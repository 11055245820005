<template>
    <div class="animated fadeIn">
		<b-card class="card-border mt-4">
            <b-card-title><i class="fa fa fa-comment"></i> Inquiries</b-card-title>
            <b-card-sub-title>List of inquiries from ayun.ph website</b-card-sub-title>
            <div fluid class="px-2 mt-4">
                <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

                <!-- Filter  -->
                <b-row class="mt-2">
                    <b-col sm="12" md="3" lg="3">
                        <b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
                            class="filter">
                            FILTER OPTIONS
                        </b-button>
                    </b-col>
                    <b-col sm="12">
                        <!-- Collapsible Filter Options -->
                        <b-collapse id="collapse-1" class="mt-2">
                            <b-card>
                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Date From">
                                            <b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
                                                reset-button label-reset-button="Clear" :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    weekday: 'short',
                                                }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                                            <span v-show="errors.has('Date From')" class="help-block">
                                                {{ errors.first('Date From') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Date To">
                                            <b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
                                                reset-button label-reset-button="Clear" :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    weekday: 'short',
                                                }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                                            <span v-show="errors.has('Date To')" class="help-block">
                                                {{ errors.first('Date To') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row no-gutters>
                                    <b-col sm="12">
                                        <b-button class="mr-1" variant="success" @click="onFilterRequest">
                                            Search
                                        </b-button>
                                        <b-button class="mr-1" variant="primary" @click="resetFilters">
                                            Reset
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-collapse>
                    </b-col>
                </b-row>

                <!-- Select Actions and Items Per Page Options -->
                <b-row>
                    <b-col sm="6" md="3" class="mt-4 mb-2">
                        <b-dropdown text=" Select Actions " variant="dark">
                            <b-dropdown-item>
                                <json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
                                    Export Inquiries in Excel
                                </json-excel>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
                                    Export Inquiries to CSV
                                </json-excel>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
                        <b-input-group prepend="Show" append="/ Page">
                            <b-form-select :options="pageOptions" v-model="perPage" />
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-table :items="items" :fields="fields" :responsive="true" selected-variant="primary"
                    :current-page="currentPage" :per-page="perPage" show-empty>
                    <template v-slot:cell(dateCreated)="row">
                        <div class="text-nowrap">
                            {{ row.item.dateCreated | toDateTimeString }}
                        </div>
                    </template>

                    <template v-slot:cell(message)="row">
                        <span class="truncate-text">
                            <truncate collapsed-text-class="collapsed" clamp="Show More" :length="50" less="Show Less"
                                type="html" :text="breakMessage(row.item.message, 25)" />
                        </span>
                    </template>

                    <template v-slot:cell(status)="row">
                        <span v-if="!row.item.status || row.item.status === 'PENDING'">
                            <b-badge variant="warning">PENDING</b-badge>
                        </span>
                        <span v-else>
                            <b-badge variant="success">DONE</b-badge>
                        </span>
                    </template>

                    <template v-slot:cell(actions)="row">
                        <div class="text-nowrap">
                            <b-button v-if="isAllowedToMarkAsDone(row.item)" title="Mark as Done" variant="primary"
                                v-b-modal.mark-inquiry-as-done v-b-tooltip.hover.top="'Mark as Done'"
                                @click.stop="updateSelInquiry(row.item)" size="sm">
                                <b-icon-check />
                            </b-button>
                            <span v-else class="done-inquiry">No further <br />action needed</span>
                        </div>
                    </template>
                </b-table>

                <b-row>
                    <b-col md="8" sm="12" class="my-1">
                        <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                    </b-col>
                    <b-col md="4" sm="12" class="my-1">
                        <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                            class="my-0" />
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- Modals -->
        <MarkInquiryAsDone />
    </div>
</template>

<script>
// Components
import MarkInquiryAsDone from './inquiries/MarkInquiryAsDone';

// Util
import { DateUtil } from '@/utils/dateutil';

// DAO
import inquiriesDAO from '@/database/inquiries';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'inquiries',
    components: {
        MarkInquiryAsDone,
        JsonExcel,
        Loading,
        truncate
    },
    data() {
        return {
            items: [],
            fields: [
                { key: 'dateCreated', label: 'Date Created', sortable: true },
                { key: 'fullName', label: 'Name', sortable: true },
                { key: 'companyName', label: 'Company', sortable: true },
                { key: 'email', label: 'Email', sortable: true },
                { key: 'phoneNumber', label: 'Phone Number', sortable: true },
                { key: 'message', label: 'Message' },
                { key: 'status', label: 'Status' },
                {
                    key: 'actions',
                    thClass: 'text-center'
                }
            ],
            currentPage: 1,
            perPage: 15,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',


            defaultFilterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
            },
            filterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
            },

            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        dateFrom() {
            const dateTo = moment();
            const dateFrom = dateTo.add(-30, 'days');
            return dateFrom.format('YYYY-MM-DD');
        },
        dateTo() {
            return moment().format('YYYY-MM-DD');
        },

        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'Date Created': 'Date Created',
                Name: 'fullName',
                Company: 'companyName',
                Email: 'email',
                'Phone Number': 'Phone Number',
                Message: 'message',
                status: 'status'
            };
        },

        fileName() {
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return 'Inquiries-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },
    },
    mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (!this.$store.getters.isSuperAdmin) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				this.defaultFilterBy.dateFrom = this.dateFrom;
                this.defaultFilterBy.dateTo = this.dateTo;
                this.resetFilters();

                await this.retrieveData();

			} catch(error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSelInquiry', () => this.retrieveData());
	},
    methods: {
        onFilterRequest() {
            this.retrieveData();
        },
        resetFilters() {
            if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
                // reset to default
                this.filterBy = { ...this.defaultFilterBy };
                this.prevFilter = { ...this.filterBy };
            }
        },
        getFilterParam() {
            let filter = { ...this.filterBy };
            filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
            filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));
            return filter;
        },
        async retrieveData() {
            try {
                // show loading indicator
                this.isLoading = true;

                let filter = this.getFilterParam();
                const inquriesObj = await inquiriesDAO.getInquiries(filter);
                this.processInquiries(inquriesObj);

            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
				// hide loading indicator
				this.isLoading = false;
			}
        },
        processInquiries(inquriesObj) {
            this.items = Object.values(inquriesObj);

            this.items.forEach((item) => {
                // Parse timestamps
                item['Date Created'] = this.getDisplayDateTime(item.dateCreated);
                item['Phone Number'] = "'" + item.phoneNumber
                item['status'] = item.status ? item.status : 'PENDING';
            });

            this.totalRows = this.items.length;
        },

        updateSelInquiry(item) {
            this.$store.commit('SET_CURR_INQUIRY', item);
        },
        isAllowedToMarkAsDone(inquiry) {
            return inquiry.status !== 'DONE';
        },

        // UTILS
        getDisplayDateTime(date) {
            if (date) {
                return DateUtil.getFormattedDateWithTime(date);
            }
            return "-";
        },
        breakMessage(message, length) {
            return message.length > length ? message.match(new RegExp(`.{1,${length}}`, 'g')).join('<br>') : message;
        },
    },
    beforeDestroy() {
        EventBus.$off('onCloseSelInquiry');
    },
};
</script>

<style scoped>
.done-inquiry {
    font-style: italic;
}
</style>